import React from 'react'
import { Link } from 'gatsby'
import Seo from "../components/seo"

const OrderFormEmpty = () => {
  return (
    <>
      <Seo title="出版・刊行物 ご注文手続き" />
      <div id="pageTitle">
        <h1>
          出版・刊行物 ご注文手続き
        </h1>
      </div>
      <div className="breadClumb">
        <ul>
          <li><Link to="/">HOME</Link></li>
          <li><Link to="/publication">出版・刊行物</Link></li>
          <li>出版・刊行物 ご注文手続き</li>
        </ul>
      </div>

      <div id="main">
        <div className="contents">
          現在、何も注文されていません。
          <div className="mb50 aC">
            <Link to="/publication" className="btn_rounded">出版・刊行物一覧へ戻る</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderFormEmpty